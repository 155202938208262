import React, { useEffect } from 'react';
import styled from 'styled-components'
import FadeIn from "react-fade-in";
import ContactPublic from '../components/contact/ContactPublic'

const Container = styled.div`
    width: 100%;
    max-width: 1084px;
    margin: 0 auto;
`

const Column = styled.div`
    @media (min-width: 768px) {
        
    }
`

const Person = styled.p`
    font-family: soleil, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: .02em;
    line-height: 1.4em;
    font-size: 14px;
    margin: 0 0 2em;
    & > a {
        color: #222;
        text-decoration: none;
    }
`



const Staff = styled.div`
    display: grid;
    grid-column-gap:  60px;
    grid-template-columns: 1fr;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1280px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`


const Info = styled.div`
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 2.5em 0;
    margin: 0 0 3em;
    @media (min-width: 768px) {
        
    }    
`

const InfoLine = styled.h1`
    color: #D8292F;
    margin: 0 0 .5em;
    text-align: left;
    font-size: 50px;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    & > a {
        text-decoration: none;
    }
    & > span {
        padding: 0 .5em;
    } 
    &:last-child {
        margin:0;
    }
`

const Contact = ({ project }) => {
    document.title = "Contact";
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <FadeIn>
                <Container>

                    <Info>
                        <InfoLine>463 Seventh Ave, New York, NY 10018</InfoLine>
                        <InfoLine>212.564.1266 <span>|</span> <a href="mailto:hello@doneger.com">hello@doneger.com</a></InfoLine>
                    </Info>

                    <Staff>
                        <Column>
                            <Person>
                                Abbey Doneger<br />
                                President<br />
                                <a href="mailto:adoneger@doneger.com">adoneger@doneger.com</a>
                            </Person>
                            <Person>
                                Leslie Ghize<br />
                                Executive Vice President<br />
                                <a href="mailto:lghize@tobereport.com">lghize@tobereport.com</a>
                            </Person>
                            <Person>
                                Tom Burns<br />
                                SVP, Client Relations + International<br />
                                <a href="mailto:tburns@doneger.com">tburns@doneger.com</a>
                            </Person>

                        </Column>
                        <Column>
                            <Person>
                                Margaret Lorenz<br />
                                SVP, Finance<br />
                                <a href="mailto:mlorenz@doneger.com">mlorenz@doneger.com</a>
                            </Person>
                            <Person>
                                Patty Leto<br />
                                SVP, Merchandising (Accessories, Kids, Mens, Outerwear, Footwear)<br />
                                <a href="mailto:pleto@doneger.com">pleto@doneger.com</a>
                            </Person>
                            <Person>
                                Kathy Bradley<br />
                                SVP, Merchandising (Sportswear)<br />
                                <a href="mailto:kbradley@doneger.com">kbradley@doneger.com</a>
                            </Person>
                        </Column>
                        <Column>
                            <Person>
                                Roseanne Cumella<br />
                                SVP, Merchandising (Dresses)<br />
                                <a href="mailto:rcumella@doneger.com">rcumella@doneger.com</a>
                            </Person>
                            <Person>
                                Shelda Hartwell<br />
                                VP, Directives West<br />
                                <a href="mailto:shelda@directiveswest.com">shelda@directiveswest.com</a>
                            </Person>

                        </Column>
                        <Column>

                            <Person>
                                Lauren Trotta<br />
                                Office Manager<br />
                                <a href="mailto:ltrotta@doneger.com">ltrotta@doneger.com</a>
                            </Person>
                        </Column>
                    </Staff>

                    {project && <ContactPublic />}
                </Container>


            </FadeIn>
        </>
    );
};

export default Contact;